import Tooltip from '@corvu/tooltip';

import styles from '~/components/player/SongLyricsButton.module.scss';
import rootStyles from '~/components/Root.module.scss';

interface SongLyricsButtonProps {
  readonly alternate?: boolean;
}

export default function SongLyricsButton(props: SongLyricsButtonProps) {
  return (
    <Tooltip
      placement="top"
      strategy="fixed"
      openDelay={200}
      hoverableContent={false}
      floatingOptions={{
        offset: 10,
        flip: true,
        shift: true,
      }}
    >
      <Tooltip.Trigger
        as="a"
        classList={{
          [styles.button]: true,
          [styles.alternate]: props.alternate,
        }}
        href="#song-lyrics"
      >
        <IconTablerPlaylist />
        <span class={rootStyles['sr-only']}>Acompanhar Letra</span>
      </Tooltip.Trigger>
      <Tooltip.Portal>
        <Tooltip.Content>
          Acompanhar Letra
          <Tooltip.Arrow size={10} />
        </Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip>
  );
}
