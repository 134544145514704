import Tooltip from '@corvu/tooltip';

import { usePlayer } from '~/components/PlayerContext';
import styles from '~/components/player/SongRequestButton.module.scss';
import rootStyles from '~/components/Root.module.scss';

interface SongRequestButtonProps {
  readonly alternate?: boolean;
}

export default function SongRequestButton(props: SongRequestButtonProps) {
  const player = usePlayer();
  const isPodcast = () => player.metadata?.program.type === 'podcast';

  const anchorProps = () => {
    if (!isPodcast()) {
      return {};
    }

    return {
      'data-disabled': true,
      'aria-hidden': true,
      onClick: (event: Event) => {
        event.preventDefault();
      },
    };
  };

  return (
    <Tooltip
      placement="top"
      strategy="fixed"
      openDelay={200}
      hoverableContent={false}
      floatingOptions={{
        offset: 10,
        flip: true,
        shift: true,
      }}
    >
      <Tooltip.Trigger
        as="a"
        href="#song-requests"
        aria-label="Interagir"
        classList={{
          [styles.button]: true,
          [styles.alternate]: props.alternate,
        }}
        {...anchorProps()}
      >
        <IconTablerMessages />
        <span class={rootStyles['sr-only']}>Interagir</span>
      </Tooltip.Trigger>
      <Tooltip.Portal>
        <Tooltip.Content>
          {isPodcast() ? 'Não é possível fazer pedidos a um podcast' : 'Pedido'}
          <Tooltip.Arrow size={10} />
        </Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip>
  );
}
