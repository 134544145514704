import { createSignal } from 'solid-js';

import { usePlayer } from '~/components/PlayerContext';
import styles from '~/components/player/Progress.module.scss';
import { formatDuration } from '~/utils/formatDuration';

interface ProgressProps {
  readonly alternate?: boolean;
}

export default function Progress(props: ProgressProps) {
  const player = usePlayer();
  const currentSongDuration = () =>
    player.metadata?.song_history[0].duration ?? -1;
  const [isReversedProgress, setIsReversedProgress] = createSignal(false);

  const transform = () =>
    `${(currentSongDuration() > 0 ? (player.progress / currentSongDuration()) * 100 : 0).toString()}%`;

  return (
    <div
      dir="ltr"
      classList={{
        [styles.wrapper]: true,
        [styles.alternate]: props.alternate,
      }}
    >
      <span
        onClick={() => {
          setIsReversedProgress(x => !x);
        }}
      >
        {formatDuration(
          isReversedProgress() && currentSongDuration() > -1
            ? player.progress - currentSongDuration()
            : player.progress,
        )}
      </span>
      <div
        classList={{
          [styles['progress-wrapper']]: true,
          [styles.indeterminate]: currentSongDuration() === -1,
        }}
      >
        <progress
          value={currentSongDuration() > -1 ? player.progress : 0}
          max={currentSongDuration() > -1 ? currentSongDuration() : 1}
        />
        <div aria-hidden="true" style={{ '--progress': transform() }} />
      </div>
      {currentSongDuration() > -1
        ? formatDuration(currentSongDuration())
        : 'VIVO'}
    </div>
  );
}
