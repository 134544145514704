import Tooltip from '@corvu/tooltip';
import { createSignal, Show } from 'solid-js';
import { Dynamic } from 'solid-js/web';

import { onPlayerLoad } from '~/components/PlayerContext';
import styles from '~/components/player/CastButton.module.scss';
import rootStyles from '~/components/Root.module.scss';

const castTypes = {
  airplay: () => (
    <svg x="0" y="0" width="1.2em" height="1.2em" viewBox="30 30 64 64">
      <path
        fill="currentcolor"
        d="M43.5,84.1l1.3-1.5c0.3-0.3,0.3-0.8,0-1.1c-10.5-9.7-11.2-26.2-1.4-36.7s26.2-11.2,36.7-1.4 s11.2,26.2,1.4,36.7c-0.5,0.5-0.9,1-1.4,1.4c-0.3,0.3-0.3,0.8,0,1.1l1.3,1.5c0.3,0.3,0.8,0.3,1.1,0.1c0,0,0,0,0,0 c12-11.1,12.7-29.7,1.7-41.7c-11.1-12-29.7-12.7-41.7-1.7s-12.7,29.7-1.7,41.7c0.5,0.6,1.1,1.1,1.7,1.7 C42.8,84.4,43.2,84.4,43.5,84.1z M44.8,62.5c0-9.7,7.9-17.6,17.6-17.6S80,52.9,80,62.6c0,4.8-2,9.5-5.5,12.8 c-0.3,0.3-0.3,0.8,0,1.1l1.3,1.5c0.3,0.3,0.8,0.4,1.1,0.1c0,0,0,0,0,0c8.5-8,8.9-21.3,1-29.8s-21.3-8.9-29.8-1S39.1,68.5,47,77 c0.3,0.3,0.6,0.7,1,1c0.3,0.3,0.8,0.3,1.1,0c0,0,0,0,0,0l1.3-1.5c0.3-0.3,0.3-0.8,0-1.1C46.9,72.1,44.8,67.4,44.8,62.5z M53.2,62.5c0-5.1,4.1-9.2,9.2-9.2c5.1,0,9.2,4.1,9.2,9.2c0,2.5-1,4.8-2.8,6.6 c-0.3,0.3-0.3,0.8,0,1.1l1.3,1.5c0.3,0.3,0.8,0.3,1.1,0c0,0,0,0,0,0c5-4.9,5.2-12.9,0.3-18s-12.9-5.2-18-0.3s-5.2,12.9-0.3,18 c0.1,0.1,0.2,0.2,0.3,0.3c0.3,0.3,0.8,0.3,1.1,0c0,0,0,0,0,0l1.3-1.5c0.3-0.3,0.3-0.8,0-1.1C54.2,67.4,53.2,65,53.2,62.5z M80.9,89.1L63.5,69.3c-0.5-0.6-1.3-0.6-1.9-0.1c0,0-0.1,0.1-0.1,0.1L43.9,89.1 c-0.4,0.5-0.4,1.2,0.1,1.7c0.2,0.2,0.5,0.3,0.7,0.3h35.3c0.6,0,1.2-0.5,1.2-1.2C81.2,89.6,81.1,89.3,80.9,89.1z"
      />
    </svg>
  ),
  chromecast: () => (
    <svg width="1.2em" height="1.2em" viewBox="0 0 24 24">
      <path
        fill="currentcolor"
        d="M1,18 L1,21 L4,21 C4,19.34 2.66,18 1,18 L1,18 Z M1,14 L1,16 C3.76,16 6,18.24 6,21 L8,21 C8,17.13 4.87,14 1,14 L1,14 Z M1,10 L1,12 C5.97,12 10,16.03 10,21 L12,21 C12,14.92 7.07,10 1,10 L1,10 Z M21,3 L3,3 C1.9,3 1,3.9 1,5 L1,8 L3,8 L3,5 L21,5 L21,19 L14,19 L14,21 L21,21 C22.1,21 23,20.1 23,19 L23,5 C23,3.9 22.1,3 21,3 L21,3 Z"
      />
    </svg>
  ),
};

const labels = {
  airplay: 'AirPlay',
  chromecast: 'Chromecast',
};

interface CastButtonProps {
  readonly alternate?: boolean;
}

export default function CastButton(props: CastButtonProps) {
  const [castType, setCastType] = createSignal<keyof typeof castTypes>();

  const label = () => `Espelhar via ${labels[castType() ?? 'airplay']}`;

  onPlayerLoad(() => {
    const { castStatus } = window.jhplayer;
    setCastType(castStatus.available ? castStatus.type : undefined);

    window.jhplayer.on('castavailabilitychange', () => {
      const { castStatus } = window.jhplayer;
      setCastType(castStatus.available ? castStatus.type : undefined);
    });
  });

  return (
    <Show when={castType()}>
      <Tooltip
        placement="top"
        strategy="fixed"
        openDelay={200}
        hoverableContent={false}
        floatingOptions={{
          offset: 10,
          flip: true,
          shift: true,
        }}
      >
        <Tooltip.Trigger
          type="button"
          classList={{
            [styles.button]: true,
            [styles.alternate]: props.alternate,
          }}
          onClick={() => {
            window.jhplayer.showCastPicker();
          }}
        >
          <Dynamic component={castTypes[castType() ?? 'airplay']} />
          <span class={rootStyles['sr-only']}>{label()}</span>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content>
            {label()}
            <Tooltip.Arrow size={10} />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip>
    </Show>
  );
}
