import { createSignal, createUniqueId, For } from 'solid-js';

import { usePlayer } from '~/components/PlayerContext';
import styles from '~/components/player/SourceSelect.module.scss';
import rootStyles from '~/components/Root.module.scss';
import Tooltip from '@corvu/tooltip';

interface SourceSelectProps {
  readonly alternate?: boolean;
}

export default function SourceSelect(props: SourceSelectProps) {
  const player = usePlayer();
  const id = createUniqueId();

  const [currentSourceIndex, setCurrentSourceIndex] = createSignal(
    player.currentSourceIndex,
  );

  return (
    <Tooltip
      placement="top"
      strategy="fixed"
      openDelay={200}
      hoverableContent={false}
      floatingOptions={{
        offset: 10,
        flip: true,
        shift: true,
      }}
    >
      <Tooltip.Anchor
        classList={{
          [styles.wrapper]: true,
          [styles.alternate]: props.alternate,
        }}
      >
        <IconTablerMusicCog />
        <Tooltip.Trigger
          as="select"
          id={id}
          onChange={(event: any) => {
            const value = Number.parseInt(event.target.value, 10);
            setCurrentSourceIndex(value);
            window.jhplayer.currentSourceIndex = value;
          }}
        >
          <For each={player.allSources}>
            {(source, index) => (
              <option
                value={index()}
                selected={index() === currentSourceIndex()}
              >
                {source}
              </option>
            )}
          </For>
        </Tooltip.Trigger>
        <label for={id} class={rootStyles['sr-only']}>
          Qualidade do streaming
        </label>
      </Tooltip.Anchor>
      <Tooltip.Portal>
        <Tooltip.Content>
          Qualidade do streaming
          <br />
          <small>{player.allSources[currentSourceIndex()]}</small>
          <Tooltip.Arrow size={10} />
        </Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip>
  );
}
